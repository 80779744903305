import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image"

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <h1>About</h1>
    <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
      <div style={{ gridColumn: 1 }}>
        <p>Hi, I'm Arne Stockmans 👋</p>
        <p>
          I'm a Belgian software engineer, experienced in building Android and
          Kotlin Multiplatform applications.
          <br />
          In the past I was the Android Tech lead at&nbsp;
          <a href="https://novemberfive.co">November Five</a>, and recently I
          joined&nbsp;
          <a href="https://inthepocket.mobi">In The Pocket</a>.
        </p>
        <p>
          When I'm not working on technology, you can find me at the karting
          track, playing theater, or studying languages.
        </p>
      </div>

      <figure style={{ gridColumn: 2 }}>
        <Img
          fixed={data.file.childImageSharp.fixed}
          style={{ margin: "1.5rem" }}
        />
      </figure>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "img.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 160, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default AboutPage
